<template>
  <BaseModal
    :value.sync="opened"
    scrollable
    title="Title from props"
    :hide-actions="false"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="8">
          <BaseInput
            v-model.trim="keywords"
            data-testid="search-unsplash-input"
            label="Search for pictures..."
            type="text"
            outlined
            dense
            name="keywords"
          ></BaseInput>
        </v-col>
        <v-col cols="12" sm="4">
          <BaseButton data-testid="search-unsplash-button" @click="getPictures">
            Search pictures
          </BaseButton>
        </v-col>
      </v-row>
    </v-container>
    <ImagesBrowser
      v-if="loaded"
      :pictures="pictures"
      :pictures-to-import="picturesToImport"
      :load-more-available="true"
      @pictureClick="handlePictureClick"
      @loadMore="loadMore"
    />
    <template v-slot:actions>
      <v-card-actions>
        <BaseButton v-if="loaded" outlined @click="loadMore">
          Load more
        </BaseButton>
        <v-spacer />
        <BaseButton v-if="picturesToImport.length > 0" @click="importPictures">
          Import
        </BaseButton>
      </v-card-actions>
    </template>
  </BaseModal>
</template>

<script>
import Vue from "vue";
import { mdiCheck } from "@mdi/js";
import ImagesBrowser from "@/components/Forms/Images/ImagesBrowser";
export default Vue.extend({
  name: "ImagesImportModal",
  components: {
    ImagesBrowser
  },
  props: {
    active: {
      type: Boolean
    }
  },
  data: () => ({
    opened: false,
    pictures: [],
    keywords: "",
    picturesToImport: [],
    paging: {
      page: 1
    },
    loaded: false,
    svgCheck: mdiCheck
  }),
  watch: {
    active(val) {
      this.opened = !!val;
    },

    opened(val) {
      if (!val) {
        this.$emit("closeModal", "unsplash");
      }
    }
  },
  methods: {
    loadMore() {
      this.paging.page++;
      this.imagesAction(this.paging.page).then(r => {
        const next = r.results.map(this._mapPicture);
        this.pictures = [...this.pictures, ...next];
      });
    },
    handlePictureClick(src) {
      if (this.picturesToImport.includes(src)) {
        this.picturesToImport.splice(this.picturesToImport.indexOf(src), 1);
      } else {
        this.picturesToImport.push(src);
      }
    },
    isChecked(src) {
      return this.picturesToImport.includes(src);
    },
    imagesAction(page = 1) {
      const url = `${process.env.VUE_APP_UNSPLASH_API_URL}/search/photos?page=${page}&query=${this.keywords}&per_page=6`;
      return fetch(url, {
        method: "get",
        headers: {
          Authorization: `Client-ID ${process.env.VUE_APP_UNSPLASH_CLIENT_ID}`
        }
      }).then(res => res.json());
    },
    _mapPicture(pic) {
      return {
        id: pic.id,
        // @ts-ignore
        media_url: pic.urls.regular
      };
    },
    getPictures() {
      this.imagesAction(1).then(res => {
        const { results, total, total_pages } = res;
        this.pictures = results.map(this._mapPicture);
        this.paging = {
          page: 1,
          total,
          totalPages: total_pages
        };
        this.loaded = true;
      });
    },
    importPictures() {
      this.$emit("picturesImport", this.picturesToImport);
    }
  }
});
</script>
