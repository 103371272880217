


































import Vue from "vue";
import { mdiCheck } from "@mdi/js";

export default Vue.extend({
  name: "ImagesBrowser",
  props: {
    loadMoreAvailable: {
      type: Boolean,
      required: true
    },
    pictures: {
      type: Array,
      required: true
    },
    picturesToImport: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    svgCheck: mdiCheck
  }),
  methods: {
    isChecked(src: string) {
      return this.picturesToImport.includes(src);
    }
  }
});
